<template>
  <div :class="{ 'colapsed-header': isColapsedBlock }">
    <colapse-button
      :isColapsedBlock="isColapsedBlock"
      @click="isColapsedBlock = !isColapsedBlock"
    />
    <div
      v-if="!isColapsedBlock"
      class="head-and-filters js-head-and-filters"
      :style="`right: ` + rightHeadPosition"
    >
      <header-page :title="'Link Building Partners'" :hasBackLink="false" />

      <div class="filters-container">
        <div class="filters-block">
          <div class="search">
            <input-text v-model="search_text" />
            <div
              v-if="isShowClear"
              class="main-button lg ml-15"
              @click="clearFilters"
            >
              <div>Clear</div>
            </div>
          </div>
          <div class="button">
            <div class="main-button lg orange" @click="isVisibleFilters = true">
              <div class="icon" v-html="Filter"></div>
              <div>Filters</div>
            </div>
          </div>
        </div>
        <div class="selected-filters" v-if="selectedFilters.length">
          <div
            class="selected-filters-groups"
            v-for="(group, key) in filterGroups"
            :key="key"
          >
            <div class="selected-filters-groups-item">
              <div
                class="icon"
                @click="removeFilterGroup(key)"
                v-html="Cross"
              ></div>
              <div class="text">{{ group[0].groupName }}</div>
              <div class="selected-filters-groups-item-sub-items">
                <template v-for="groupItem in group" :key="groupItem.text">
                  <div
                    v-if="!isArray(groupItem.text)"
                    class="selected-filters-item"
                  >
                    <div
                      class="icon"
                      @click="removeFilter(groupItem.id)"
                      v-html="Cross"
                    ></div>
                    <div class="text">
                      {{ groupItem.label }} {{ groupItem.text }}
                    </div>
                  </div>

                  <div
                    v-else
                    class="selected-filters-item"
                    v-for="groupItemArray in groupItem.text"
                    :key="groupItemArray"
                  >
                    <div
                      class="icon"
                      @click="
                        removeFilterArrayItem(groupItem.id, groupItemArray)
                      "
                      v-html="Cross"
                    ></div>
                    <div class="text">{{ groupItemArray }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      v-model:selectedRows="selectedRows"
      v-model:options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      :listTabs="listTabs"
      @setTab="setTab"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntity"
      @saveRow="saveEntity"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      @changedColumnsParams="changedColumnsParams"
    />
    <set-filters
      v-if="isVisibleFilters"
      v-model="isVisibleFilters"
      :setFilterComponent="setFilterComponent"
      :filters="filters"
      @setFilters="setFilters"
    />
  </div>
</template>

<style lang="scss">
.partner-group-block {
  border: 1px solid #dddae9;
  border-radius: 15px;
  padding: 10px 15px 20px 15px;
  margin-bottom: 20px;
}
</style>

<script>
import { shallowRef } from 'vue'
import SetFilterComponent from '@/components/partner/SetFilters/SetFiltersPartners'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { listMixin } from '@/utils/list-mixin'
import { hasAccess, permissions } from '@/utils/permissions'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'LinkBuilderPartners',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    let filterKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isCardEditable: true,
        pathToEdit: 'link-builder-partner',
        isSetColumns: true,
        isEditable: hasAccess(permissions.update_partner),
        isDelete: hasAccess(permissions.delete_partners),
        isAdd: hasAccess(permissions.create_partner),
        isExportToCSV: hasAccess(permissions.export_partners_to_csv),
        addButtonText: 'Add Partner',
        getDeleteContent: async (rows) => {
          if (!rows) return ``

          let getHTML = (params) => {
            let stringHTML = ''
            for (const key in params) {
              if (typeof params[key] === 'object') {
                stringHTML += `
                  <div class="mt-10">
                    <span class="delete-label">${key.replaceAll(
                      '_',
                      ' '
                    )}</span>
                  </div>`

                for (const keyChild in params[key]) {
                  stringHTML += `
                      <div>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ${params[key][keyChild]}</span>
                      </div>`
                }
              } else {
                stringHTML += `
                  <div class="mt-10">
                    <span class="delete-label">${key.replaceAll(
                      '_',
                      ' '
                    )}</span>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ${params[key]}</div>
                  </div>`
              }
            }

            return stringHTML
          }

          let idsQuery = rows
            .map((row) => {
              return `partner_ids=${row.id}`
            })
            .join('&')
          let result = ''
          let infoList = await fetchData(
            urls.deletePartnerInfo,
            'GET',
            null,
            idsQuery
          )
          for (let info of infoList)
            result += `<div class="partner-group-block">${getHTML(info)}</div>`

          return result
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'id',
          text: 'Partner ID',
          positionDefault: colsKeyUrlIndex,
          width: '80px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'company_name',
          text: 'Company name',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row, options }) => {
            if (value)
              return `<a href="${
                window.location.origin + '/' + options.pathToEdit + '/' + row.id
              }" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'email',
          text: 'Company email',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="mailto:${value}" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'decision_maker',
          text: 'Partner name',
          positionDefault: colsKeyUrlIndex,
          width: '300px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'slack_community_status',
          text: 'Slack Community Status',
          positionDefault: colsKeyUrlIndex,
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_builder',
          text: 'Link Builder',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'number_of_sites',
          text: 'Number of sites',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'sites',
          text: 'Sites',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row }) => {
            let result = value

            let isContainsString = (value) => {
              if (!value || this.search_text.value?.length < 3) return false
              return (value + '')
                .toLowerCase()
                .includes((this.search_text.value + '').toLowerCase())
            }

            if (typeof value === 'object' && value?.length) {
              //short-list
              result = '<div class="short-list">'
              let iconShort = `
              <span class="colapse js-colapse" data-id="${row.id}">
                  <svg class="js-colapse" data-id="${row.id}" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="colapse js-colapse" data-id="${row.id}" fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z"/>
                </svg>
                </span>
              `
              let countShort = `(${value.length})`
              if (value.length < 2) {
                iconShort = ''
                countShort = ''
              }
              result += `<div><a class="bold-underline ${
                isContainsString(value[0]?.url) ? 'includes' : ''
              }" href="${
                value[0]?.url.includes('http')
                  ? value[0]?.url
                  : '//' + value[0]?.url
              }" title="${value[0]?.url}" target="_blank" >${
                value[0]?.url
              }${countShort}</a>
                ${iconShort}
                </div>`
              result += '</div>'

              //long-list
              result += '<div class="long-list">'
              let i = 0
              let iconLong = `
                <span class="colapse js-colapse" data-id="${row.id}">
                  <svg class="js-colapse" data-id="${row.id}" viewBox="0 0 10 7" transform="scale(-1 -1)" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="colapse js-colapse" data-id="${row.id}" fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z"/>
                  </svg>
                </span>`
              let countLong = `(${value.length})`
              for (const element of value) {
                if (i > 0) {
                  iconLong = ''
                  countLong = ''
                }
                result += `<div><a class="bold-underline ${
                  isContainsString(element?.url) ? 'includes' : ''
                }" href="${
                  element?.url.includes('http')
                    ? element?.url
                    : '//' + element?.url
                }" title="${element?.url}"target="_blank">${
                  element?.url
                }${countLong}</a>
                  ${iconLong}
                  </div>`
                i++
              }
              result += '</div>'
            }

            return result
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_gap',
          text: 'Link Gap',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'published_links_partner',
          text: 'Published links from partner',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'published_slack_community_links_partner',
          text: 'Published Slack Community links from Partner',
          positionDefault: colsKeyUrlIndex,
          width: '130px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_pub_link_partner',
          text: 'Last link published date from a partner',
          positionDefault: colsKeyUrlIndex,
          width: '130px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'published_links_from_do',
          text: 'Published links from DO',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_pub_link_do',
          text: 'Last link published date from DO',
          positionDefault: colsKeyUrlIndex,
          width: '130px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'pending_links_partner',
          text: 'Pending links from Partner',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'pending_links_from_do',
          text: 'Pending links from DO',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'declined_links_partner',
          text: 'Declined links from Partner',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_created',
          text: 'Date of creation of the partner card',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'scheduled',
          text: 'Scheduled Links',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'status',
          text: 'Status',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'slack_community',
          text: 'Slack community',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_status_changed',
          text: 'Company Status Changed date',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_owner_changed',
          text: 'Owner Changed date',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'invite_status',
          text: 'Invite status',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        /*  {
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'notes',
          text: 'Comments',
          positionDefault: colsKeyUrlIndex,
        },*/
      ],
      rows: [],
      page: 1,
      per_page: 100,
      newRowComponent: shallowRef(/*NewRowEntity*/ null),
      setFilterComponent: shallowRef(SetFilterComponent),
      filters: {},
      listKey: 'partners',
      groupToFilter: {
        invite_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'invite_status_f',
          name: 'Invite status',
        },
        status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'status_f',
          name: 'Partner Status',
        },
        slack_community_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'slack_community_status_f',
          name: 'Slack Community status',
        },
        published_links_from_do_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_from_do_f',
          name: 'Published links from DO',
        },
        published_links_from_do_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_from_do_f',
          name: 'Published links from DO',
        },
        pending_links_from_do_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_from_do_f',
          name: 'Pending links from DO',
        },
        pending_links_from_do_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_from_do_f',
          name: 'Pending links from DO',
        },
        published_links_partner_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_partner_f',
          name: 'Published link from partner',
        },
        published_links_partner_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_partner_f',
          name: 'Published link from partner',
        },
        pending_links_partner_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_partner_f',
          name: 'Pending links from Partner',
        },
        pending_links_partner_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_partner_f',
          name: 'Pending links from Partner',
        },
        link_gap_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_gap_f',
          name: 'Link Gap',
        },
        link_gap_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_gap_f',
          name: 'Link Gap',
        },
        link_builder_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_builder_f',
          name: 'Link Builder',
        },
        last_link_published_day_from_partner_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'last_link_published_day_from_partner_f',
          name: 'Last link published date from Partner',
        },
        last_link_published_day_from_partner_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'last_link_published_day_from_partner_f',
          name: 'Last link published date from Partner',
        },
        last_link_published_day_from_do_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'last_link_published_day_from_do_f',
          name: 'Last link published date from DO',
        },
        last_link_published_day_from_do_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'last_link_published_day_from_do_f',
          name: 'Last link published date from DO',
        },
        date_of_creation_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_of_creation_f',
          name: 'Date of creation of the partner card',
        },
        date_of_creation_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_of_creation_f',
          name: 'Date of creation of the partner card',
        },
        date_owner_changed_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_owner_changed_f',
          name: 'Owner Changed date',
        },
        date_owner_changed_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_owner_changed_f',
          name: 'Owner Changed date',
        },
        date_status_changed_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_status_changed_f',
          name: 'Company Status Changed date',
        },
        date_status_changed_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_status_changed_f',
          name: 'Company Status Changed date',
        },
        declined_links_partner_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'declined_links_partner_f',
          name: 'Declined links from Partner',
        },
        declined_links_partner_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'declined_links_partner_f',
          name: 'Declined links from Partner',
        },
        // slack_community_f: {
        //   keyUrl: 'f' + filterKeyUrlIndex++,
        //   key: 'slack_community_f',
        //   name: 'Slack community',
        // },
        slack_community_lbp_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'slack_community_lbp_f',
          name: 'Slack community',
        },
        slack_community_lbp_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'slack_community_lbp_f',
          name: 'Slack community',
        },
      },
      isColapsedBlock: false,

      listTabs: [
        {
          id: 'all',
          name: 'All partners',
          count: 0,
          isActive: true,
          isVisible: true,
        },
        {
          id: 'my-partners',
          name: 'My partners',
          count: 0,
          isActive: false,
          isVisible: true,
        },
        {
          id: 'my-div',
          name: 'My division',
          count: 0,
          isActive: false,
          isVisible: false,
        },
      ],
      activeTab: 'all',
    }
  },

  created() {
    this.setDefaultColumnsParams() // 1
    this.setMetaDataColumnsParams() // 2
    this.setDataFromQueryParams() // 3
    this.loadCountTabs()
    this.fetchData()
  },

  methods: {
    async loadCountTabs() {
      let query = `page=1&per_page=1`

      let partnersAll = await fetchData(
        urls.partners,
        'GET',
        null,
        query + `&partners_f=all`
      )
      let partnersAllTab = this.listTabs.find((item) => item.id === 'all')
      partnersAllTab.count = partnersAll?.count || 0

      let partnersMy = await fetchData(
        urls.partners,
        'GET',
        null,
        query + `&partners_f=my`
      )
      let partnersMyTab = this.listTabs.find(
        (item) => item.id === 'my-partners'
      )
      partnersMyTab.count = partnersMy?.count || 0

      let partnersMyDiv = await fetchData(
        urls.partners,
        'GET',
        null,
        query + `&partners_f=div`
      )
      let partnersMyDivTab = this.listTabs.find((item) => item.id === 'my-div')
      partnersMyDivTab.count = partnersMyDiv?.count || 0
      if (partnersMyDivTab.count) partnersMyDivTab.isVisible = true
    },

    setTab(id) {
      if (!id) return

      this.activateTab(id)
      this.activeTab = id
    },

    saveNewEntity(entity) {
      this.setNewEntity(entity)
      this.addRow()
    },

    async editRow(entity) {
      this.$store.commit('setIsLoading', true)
      const id = entity.id
      delete entity.id

      let result = await fetchData(
        urls.partners + `/${id}`,
        'POST',
        null,
        this.entityQuery(entity)
      )
      if (!result?.detail) {
        let entityRow = this.rows.find((item) => item.id === id)
        for (const key in entity) {
          if (key in entityRow) entityRow[key] = entity[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.partners,
        'POST',
        null,
        this.entityQuery()
      )
      if (!result?.detail) {
        const tmpEntity = this.newEntity
        this.rows.unshift({ ...{ id: result.id }, ...tmpEntity })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newEntityToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ''
        for (const id of ids) {
          query += `partner_ids=${id}&`
        }

        return query
      }

      let query = ''

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${
          this.queryToSort() & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(urls.partners, 'DELETE', null, query)
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = '&'
        for (const id of ids) {
          query += `partner_ids=${id}&`
        }

        if (query === '&') {
          for (const row of this.rows) {
            query += `partner_ids=${row.id}&`
          }
        }

        if (this.options.isShowAllColumns) {
          query += `all_columns=true&`
        } else {
          query += `all_columns=false&`
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(urls.partnersExportToCsv, 'GET', 'partners', null, query)

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      this.$nextTick(() => {
        this.setHeightGridScrolableBlock()
      })
      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = ''
      let prevSearch_text = this.search_text.value

      query = `${this.queryToFetch()}&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(urls.partners, 'GET', null, query)

      this.rows = result?.rows || []

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    queryToFilters() {
      let searchParams = new URLSearchParams()
      //let query = ''
      for (const key in this.filters) {
        if (typeof this.filters[key] === 'object') {
          for (const value of this.filters[key]) {
            //query +=`${key}=${value}&`
            searchParams.append(key, value)
          }
        } else {
          //query +=`${key}=${this.filters[key]}&`
          searchParams.append(key, this.filters[key])
        }
      }

      switch (this.activeTab) {
        case 'all':
          searchParams.append('partners_f', 'all')
          //query += `&partners_f=all`
          break

        case 'my-partners':
          searchParams.append('partners_f', 'my')
          //query += `&partners_f=my`
          break

        case 'my-div':
          searchParams.append('partners_f', 'div')
          //query += `&partners_f=div`
          break

        //default:
        //query += ``
      }

      //return query
      return searchParams.toString()
    },
  },
}
</script>
