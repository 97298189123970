<template>
  <div class="filters-form">
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">
          {{ labels.last_link_published_day_from_partner_f_from }}
        </div>
        <div class="form-inline">
          <div>
            <input-date-picker
              v-model="last_link_published_day_from_partner_f_from"
            />
          </div>
          <div>
            <input-date-picker
              v-model="last_link_published_day_from_partner_f_to"
            />
          </div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">{{ labels.pending_links_partner_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="pending_links_partner_f_from" /></div>
          <div><input-number v-model="pending_links_partner_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">
          {{ labels.last_link_published_day_from_do_f_from }}
        </div>
        <div class="form-inline">
          <div>
            <input-date-picker
              v-model="last_link_published_day_from_do_f_from"
            />
          </div>
          <div>
            <input-date-picker v-model="last_link_published_day_from_do_f_to" />
          </div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">{{ labels.pending_links_from_do_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="pending_links_from_do_f_from" /></div>
          <div><input-number v-model="pending_links_from_do_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">
          {{ labels.published_links_partner_f_from }}
        </div>
        <div class="form-inline">
          <div><input-number v-model="published_links_partner_f_from" /></div>
          <div><input-number v-model="published_links_partner_f_to" /></div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">{{ labels.declined_links_partner_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="declined_links_partner_f_from" /></div>
          <div><input-number v-model="declined_links_partner_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">
          {{ labels.published_links_from_do_f_from }}
        </div>
        <div class="form-inline">
          <div><input-number v-model="published_links_from_do_f_from" /></div>
          <div><input-number v-model="published_links_from_do_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.link_gap_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="link_gap_f_from" /></div>
          <div><input-number v-model="link_gap_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="status_f"
              @changeValue="changeValueStatus_f"
            />
          </div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">{{ labels.date_of_creation_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_of_creation_f_from" /></div>
          <div><input-date-picker v-model="date_of_creation_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.invite_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="invite_status_f"
              @changeValue="changeValueInvite_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">
          {{ labels.date_status_changed_f_from }}
        </div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_status_changed_f_from" /></div>
          <div><input-date-picker v-model="date_status_changed_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.link_builder_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="link_builder_f"
              @changeValue="changeValueLink_builder_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.date_owner_changed_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_owner_changed_f_from" /></div>
          <div><input-date-picker v-model="date_owner_changed_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.slack_community_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="slack_community_status_f"
              @changeValue="changeValueSlack_community_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.slack_community_lbp_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="slack_community_lbp_f_from" /></div>
          <div><input-number v-model="slack_community_lbp_f_to" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from '@/components/inputs/InputDatePicker'
import InputSelect from '@/components/inputs/InputSelect'
import InputNumber from '@/components/inputs/InputNumber'
import { fetchData, urls } from '@/utils/urls.js'
import { filtersMixin } from '@/utils/filters-mixin'

export default {
  name: 'SetFiltersPartners',
  mixins: [filtersMixin],
  components: { InputDatePicker, InputSelect, InputNumber },

  data() {
    return {
      invite_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      published_links_from_do_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      published_links_from_do_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_from_do_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_from_do_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      published_links_partner_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      published_links_partner_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_partner_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_partner_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      link_gap_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      link_gap_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      link_builder_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      slack_community_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      last_link_published_day_from_partner_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      last_link_published_day_from_partner_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      last_link_published_day_from_do_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      last_link_published_day_from_do_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_of_creation_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_of_creation_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_owner_changed_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_owner_changed_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_status_changed_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_status_changed_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      declined_links_partner_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      declined_links_partner_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      slack_community_lbp_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      slack_community_lbp_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      labels: {
        invite_status_f: 'Invite status',
        status_f: 'Partner Status',
        published_links_from_do_f_from: 'Published links from DO',
        published_links_from_do_f_to: 'Published links from DO',
        pending_links_from_do_f_from: 'Pending links from DO',
        pending_links_from_do_f_to: 'Pending links from DO',
        published_links_partner_f_from: 'Published link from partner',
        published_links_partner_f_to: 'Published link from partner',
        pending_links_partner_f_from: 'Pending links from Partner',
        pending_links_partner_f_to: 'Pending links from Partner',
        link_gap_f_from: 'Link Gap',
        link_gap_f_to: 'Link Gap',
        link_builder_f: 'Link Builder',

        last_link_published_day_from_partner_f_from:
          'Last link published date from Partner',
        last_link_published_day_from_partner_f_to:
          'Last link published date from Partner',
        last_link_published_day_from_do_f_from:
          'Last link published date from DO',
        last_link_published_day_from_do_f_to:
          'Last link published date from DO',
        date_of_creation_f_from: 'Date of creation of the partner card',
        date_of_creation_f_to: 'Date of creation of the partner card',

        date_owner_changed_f_from: 'Owner Changed date',
        date_owner_changed_f_to: 'Owner Changed date',
        date_status_changed_f_from: 'Company Status Changed date',
        date_status_changed_f_to: 'Company Status Changed date',

        declined_links_partner_f_from: 'Declined links from Partner',
        declined_links_partner_f_to: 'Declined links from Partner',

        slack_community_status_f: 'Slack Community status',
        slack_community_lbp_f_from: 'Slack Community',
        slack_community_lbp_f_to: 'Slack Community',
      },
    }
  },

  created() {
    this.fetchDataStatus_f()
    this.fetchDataLink_builder_f()
    this.fetchDataInvite_status_f()
    this.fetchDataSlack_community_status_f()

    for (let key in this.filters) {
      const element = this.filters[key]
      if (key in this.$data) {
        this.$data[key].value = element
      }
    }
  },

  methods: {
    changeValueSlack_community_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', {
          key: 'slack_community_status_f',
          value: value,
          group: this.labels.slack_community_status_f,
        })
      else
        this.$emit('setFilterValue', {
          key: 'slack_community_status_f',
          value: null,
          group: this.labels.slack_community_status_f,
        })
    },

    changeValueInvite_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'invite_status_f', value: value })
      else this.$emit('setFilterValue', { key: 'invite_status_f', value: null })
    },
    changeValueStatus_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'status_f', value: value })
      else this.$emit('setFilterValue', { key: 'status_f', value: null })
    },

    changeValueLink_builder_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'link_builder_f', value: value })
      else this.$emit('setFilterValue', { key: 'link_builder_f', value: null })
    },

    async fetchDataInvite_status_f() {
      let query = `page=1&per_page=all&invite_status=asc`
      let result = await fetchData(urls.inviteStatuses, 'GET', null, query)

      this.invite_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.invite_status,
          name: item.invite_status,
        }
      })
    },

    async fetchDataStatus_f() {
      let query = `page=1&per_page=all&partner_status=asc`
      let result = await fetchData(urls.partnerStatuses, 'GET', null, query)

      this.status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.partner_status,
          name: item.partner_status,
        }
      })
    },

    async fetchDataLink_builder_f() {
      let query = `page=1&per_page=all&email=asc`
      let result = await fetchData(urls.users, 'GET', null, query)

      this.link_builder_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.email,
          name: item.email,
        }
      })
    },

    async fetchDataSlack_community_status_f() {
      let query = `page=1&per_page=all&slack_community_status=asc`
      let result = await fetchData(
        urls.slackCommunityStatus,
        'GET',
        null,
        query
      )

      this.slack_community_status_f.dataList = (result?.rows || []).map(
        (item) => {
          return {
            id: item.slack_community_status,
            name: item.slack_community_status,
          }
        }
      )
    },
  },
}
</script>
